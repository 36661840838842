import {
    ArrowPathIcon,
    ChevronDownIcon,
    CursorArrowRaysIcon,
    RectangleGroupIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/outline'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {useTranslations} from 'next-intl'

import {Link} from '@/i18n/routing'

import {navLinks} from './NavLinks'

type ProductLink = {
    name: 'appointments' | 'engagement' | 'automations' | 'integrations'
    href: '/#appointments' | '/#medical' | '/#finance' | '/integrations'
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

function getNavProducts() {
    return {
        products: [
            {
                name: 'appointments',
                href: navLinks.appointments as '/#appointments',
                icon: RectangleGroupIcon,
            },
            {
                name: 'engagement',
                href: navLinks.medical as '/#medical',
                icon: CursorArrowRaysIcon,
            },
            {
                name: 'automations',
                href: navLinks.finance as '/#finance',
                icon: ArrowPathIcon,
            },
            {
                name: 'integrations',
                href: navLinks.integrations as '/integrations',
                icon: SquaresPlusIcon,
            },
        ] as ProductLink[],
    } as const
}

export default function NavProductMenu() {
    const t = useTranslations('Nav')
    const {products} = getNavProducts()

    return (
        <NavigationMenu.Root>
            <NavigationMenu.List className="lg:gap-x-8">
                <NavigationMenu.Item>
                    <div className="relative group/chevron">
                        <NavigationMenu.Trigger className="flex items-center gap-x-1 text-base font-medium text-gray-900 hover:bg-slate-300/25 px-3 h-9 rounded-md">
                            {t('topLevel.product')}
                            <ChevronDownIcon
                                className="size-4 flex-none text-slate-900/35 transition-transform duration-700 group-hover/chevron:rotate-180"
                                aria-hidden="true"
                            />
                        </NavigationMenu.Trigger>
                        <NavigationMenu.Content className="absolute top-full z-50 mt-1 w-max max-w-xl overflow-hidden rounded-xl backdrop-blur-3xl bg-white border border-slate-200/45 shadow-lg animate-scale-in">
                            <ul className="flex flex-col p-2 gap-2">
                                {products.map((item) => (
                                    <li
                                        key={item.name}
                                        className="list-item group/item rounded-lg leading-6 hover:bg-slate-200/55"
                                        tabIndex={0}
                                    >
                                        <Link
                                            href={item.href}
                                            className="relative flex gap-x-2 p-2.5"
                                        >
                                            <div className="flex size-4 flex-none items-start">
                                                <item.icon
                                                    className="size-6 text-gray-400 group-hover/item:text-gray-900"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="flex-auto">
                                                <span className="block text-base font-semibold text-gray-900">
                                                    {t(`${item.name}.title`)}
                                                </span>
                                                <span className="text-gray-500 text-sm group-hover/item:text-gray-900">
                                                    {t(`${item.name}.description`)}
                                                </span>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </NavigationMenu.Content>
                    </div>
                </NavigationMenu.Item>
            </NavigationMenu.List>
        </NavigationMenu.Root>
    )
}
