import React from 'react'

export default function useGlobalKey(key: string, callback: () => void) {
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const activeElement = document.activeElement as HTMLElement
            const isInputField =
                activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable

            if (!isInputField && event.key.toLowerCase() === key.toLowerCase()) {
                event.preventDefault()
                callback()
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [key, callback])
}
