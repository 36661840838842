import {cva} from 'class-variance-authority'

export const layoutClasses = cva('flex flex-col box-border', {
    variants: {
        variant: {
            primary:
                'mx-auto max-w-7xl w-full pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]',
            secondary: 'mx-auto max-w-7xl w-full py-6 gap-20 mb-40',
            content:
                'mx-auto max-w-7xl w-full pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]',
            root: 'before:block before:pt-[calc(var(--navigation-height)+var(--navigation-top))] pt-[--page-padding-top]',
            center: 'min-w-0 items-center justify-center',
            left: '',
        },
    },
})

export const containerClasses = cva(
    'w-full mx-auto pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]',
    {
        variants: {
            variant: {
                primary: 'max-w-7xl flex flex-col text-center',
                secondary: 'max-w-3xl',
                flexrow: 'max-w-7xl flex flex-col lg:flex-row',
                flexcol: 'max-w-7xl flex flex-col',
                content: 'max-w-[var(--page-max-content)]',
            },
        },
    },
)
