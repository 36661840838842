'use client'
import Link from 'next/link'
import {useTranslations} from 'next-intl'
import {useCallback, useState} from 'react'

import HamburgerMenu from '@/app/[locale]/(website)/components/icons/hamburger'
import Logo from '@/app/[locale]/(website)/components/icons/logo'
import LayoutContent from '@/app/[locale]/(website)/components/layout/LayoutContent'
import LocaleSwitcher from '@/app/[locale]/(website)/components/LocaleSwitcher'
import RenderMobileNav from '@/app/[locale]/(website)/components/navigation/renderMobileNav'
import RenderNonMobileNav from '@/app/[locale]/(website)/components/navigation/renderNonMobileNav'
import HotkeyButton from '@/app/[locale]/(website)/components/navigation/ToFluxButton'
import {buttonClasses} from '@/app/[locale]/(website)/components/ui/ButtonClasses'
import InternalButton from '@/app/[locale]/(website)/components/ui/internalbutton'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const t = useTranslations('Nav')
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const toggleMenu = useCallback(() => {
        setMobileMenuOpen((open) => !open)
    }, [])

    const closeMenu = useCallback(() => {
        setMobileMenuOpen(false)
        resetHamburgerState()
    }, [])

    const resetHamburgerState = () => {
        const hamburgerButton = document.querySelector('button[aria-pressed]')
        if (hamburgerButton) {
            hamburgerButton.setAttribute('aria-pressed', 'false')
        }
    }

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            toggleMenu()
            const pressed = event.currentTarget.getAttribute('aria-pressed') === 'true'
            event.currentTarget.setAttribute('aria-pressed', (!pressed).toString())
        },
        [toggleMenu],
    )

    return (
        <header className="fixed backdrop-blur-lg bg-white/30 top-0 left-0 w-full border-b border-b-slate-200/25 h-[var(--navigation-height)] z-[var(--layer-navigation)] ">
            <LayoutContent variant={'primary'} className="max-lg:!pr-0">
                <nav
                    className="block relative w-full overflow-x-auto lg:overflow-visible"
                    role="navigation"
                    aria-label="Main"
                    data-orientation="horizontal"
                >
                    <ul className="relative flex items-center justify-between gap-3 lg:gap-6">
                        <li className="flex lg:flex-1">
                            <Link
                                href="/"
                                className="flex items-center h-[var(--navigation-height)]"
                                role="link"
                                aria-label="Navigate home"
                                rel="noopener"
                                onClick={closeMenu}
                            >
                                <Logo className="size-8 mr-2" />
                                <span className="text-lg font-semibold text-slate-900/85">
                                    {t('topLevel.flux')}
                                </span>
                            </Link>
                        </li>
                        <li
                            className={classNames(
                                'flex h-full lg:hidden transition-[visibility]',
                                mobileMenuOpen ? 'visible' : 'invisible delay-500',
                            )}
                        >
                            <RenderMobileNav
                                mobileMenuOpen={mobileMenuOpen}
                                onCloseMenu={closeMenu}
                            />
                        </li>
                        <li className="hidden lg:block">
                            <RenderNonMobileNav />
                        </li>
                        <li className="flex-1 ">
                            <ul className="flex flex-row space-x-2 justify-end items-center">
                                <li className="hidden lg:block">
                                    <LocaleSwitcher />
                                </li>
                                <li>
                                    <InternalButton
                                        href="/contact"
                                        variant="vibrant"
                                        size="medium"
                                        shape="roundXL"
                                    >
                                        {t('topLevel.signUp')}
                                    </InternalButton>
                                </li>
                                <li>
                                    <HotkeyButton
                                        label={t('topLevel.openApp')}
                                        title={t('topLevel.loginTitle')}
                                        url="https://flux.healthcare"
                                        ariaLabel={t('topLevel.loginLabel')}
                                    />
                                </li>
                                <li className="lg:hidden">
                                    <button
                                        type="button"
                                        className={buttonClasses({
                                            variant: 'hamburger',
                                        })}
                                        aria-pressed="false"
                                        onClick={handleClick}
                                    >
                                        <span className="sr-only">
                                            {mobileMenuOpen
                                                ? t('topLevel.mobilemenuclose')
                                                : t('topLevel.mobilemenuopen')}
                                        </span>
                                        <HamburgerMenu />
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </LayoutContent>
        </header>
    )
}
