import {groq} from 'next-sanity'

import {LocalizedSlug} from '@/app/[locale]/(website)/types/types'
import {client} from '@/sanity/lib/client'

export async function getLocalizedSlugs(type: string, slug: string): Promise<LocalizedSlug[]> {
    return (
        await client.fetch(
            groq`*[_type == $type && slug.current == $slug] {
            "_translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
                "slug": slug.current,
                language
            }
        }`,
            {type, slug},
        )
    )[0]._translations
}
