'use client'

import {ChevronDownIcon} from '@heroicons/react/24/solid'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {useParams} from 'next/navigation'
import {useCallback, useEffect, useState, useTransition} from 'react'

import {Pathnames, SupportedLocales, usePathname, useRouter} from '@/i18n/routing'
import {getLocalizedSlugs} from '@/sanity/queries/getAllLocalizedSlugs'

import getTypeForPath from '../hooks/getTypeForPath'
import {LocalizedSlug} from '../types/types'

type Params = Record<string, string | string[] | undefined>

type RouteObject = {
    pathname: Pathnames
    params?: Params
}

interface Props {
    defaultValue: string
    label: string
    children?: React.ReactNode
}

export default function LocaleSwitcherSelect({defaultValue, label, children}: Props) {
    const router = useRouter()
    const [, startTransition] = useTransition()
    const pathname = usePathname()
    const params = useParams()
    const slug = params?.slug as string | undefined
    const locale = params?.locale as string | undefined
    const type = getTypeForPath(pathname)

    const [localizedSlugs, setLocalizedSlugs] = useState<LocalizedSlug[] | null>(null)
    const [selectedLocale, setSelectedLocale] = useState(defaultValue)

    const getTranslatedSlug = useCallback(async () => {
        if (slug && type) {
            try {
                const fetchedSlugs = await getLocalizedSlugs(type, slug)
                setLocalizedSlugs(fetchedSlugs)
            } catch (error) {
                console.error('Error fetching localized slugs:', error)
                setLocalizedSlugs(null)
            }
        }
    }, [type, slug])

    useEffect(() => {
        if (locale && slug) {
            getTranslatedSlug()
        }
    }, [locale, slug, getTranslatedSlug])

    function onOptionClick(nextLocale: SupportedLocales) {
        // Voorkom actie als de geselecteerde taal dezelfde is als de huidige
        if (nextLocale === selectedLocale) return

        setSelectedLocale(nextLocale)

        startTransition(() => {
            const routeObject: RouteObject = {
                pathname: pathname as Pathnames,
                params: {...params},
            }

            if (slug && localizedSlugs) {
                const matchingSlug = localizedSlugs.find(
                    (slugObj) => slugObj.language === nextLocale,
                )?.slug
                if (matchingSlug) {
                    routeObject.params = {...params, slug: matchingSlug}
                }
            }

            router.replace(routeObject as any, {locale: nextLocale})
        })
    }

    return (
        <NavigationMenu.Root>
            <NavigationMenu.List className="gap-x-8">
                <NavigationMenu.Item>
                    <div className="relative group/chevron">
                        <NavigationMenu.Trigger className="flex items-center gap-x-1 text-base font-medium text-gray-900 hover:bg-slate-300/25 px-3 h-9 rounded-md">
                            {label}
                            <p className="sr-only">{label}</p>
                            <ChevronDownIcon
                                className="size-4 flex-none text-slate-900/35 transition-transform duration-700 group-hover/chevron:rotate-180"
                                aria-hidden="true"
                            />
                        </NavigationMenu.Trigger>

                        <NavigationMenu.Content className="absolute top-full z-50 mt-1 w-max max-w-xl overflow-hidden rounded-xl backdrop-blur-3xl bg-white border border-slate-200/45 shadow-lg right-0 lg:left-0 lg:right-auto animate-scale-in">
                            <ul className="flex flex-col p-2 gap-2">
                                {children &&
                                    (children as React.ReactNode[]).map((child) => {
                                        const optionElement = child as React.ReactElement<{
                                            value: SupportedLocales
                                            children: React.ReactNode
                                        }>

                                        const isActive =
                                            optionElement.props.value === selectedLocale

                                        return (
                                            <li
                                                key={optionElement.props.value}
                                                className={`list-item group rounded-lg leading-6 
                        ${isActive ? 'cursor-default opacity-50 pointer-events-none' : 'cursor-pointer'}
                        hover:${isActive ? 'bg-transparent' : 'bg-slate-200/55'}
                        p-2`}
                                                onClick={() =>
                                                    !isActive &&
                                                    onOptionClick(optionElement.props.value)
                                                }
                                            >
                                                {optionElement.props.children}
                                            </li>
                                        )
                                    })}
                            </ul>
                        </NavigationMenu.Content>
                    </div>
                </NavigationMenu.Item>
            </NavigationMenu.List>
        </NavigationMenu.Root>
    )
}
