'use client'

import {useRouter} from 'next/navigation'
import {useSyncExternalStore, useTransition} from 'react'

import {disableDraftMode} from './actions'

/* eslint-disable-next-line @typescript-eslint/no-empty-function */
const emptySubscribe = () => () => {}

export default function AlertBanner() {
    const router = useRouter()
    const [pending, startTransition] = useTransition()

    const shouldShow = useSyncExternalStore(
        emptySubscribe,
        () => window.top === window,
        () => false,
    )

    const handleDisableDraftMode = () => {
        startTransition(() => {
            disableDraftMode().then(() => {
                router.refresh()
            })
        })
    }

    if (!shouldShow) return null

    return (
        <div
            className={`${
                pending ? 'animate-pulse' : ''
            } fixed top-[var(--navigation-height)] left-0 h-[calc(var(--navigation-height)/2)] z-[var(--layer-navigation)] w-full border-b border-b-slate-200/25 bg-white/50 text-black backdrop-blur`}
        >
            <div className="text-center text-sm font-medium">
                {pending ? (
                    'Disabling draft mode...'
                ) : (
                    <>
                        {'Previewing drafts. '}
                        <button
                            type="button"
                            onClick={handleDisableDraftMode}
                            className="hover:underline transition-all duration-200 ease-in-out"
                        >
                            Back to published
                        </button>
                    </>
                )}
            </div>
        </div>
    )
}
