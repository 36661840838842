type PageType = 'changelog' | 'customer' | 'blog' | 'integration'

export default function getTypeForPath(path: string): PageType | null {
    if (path.includes('/changelog') || path.includes('/updates')) {
        return 'changelog'
    }
    if (path.includes('/customer') || path.includes('/klanten')) {
        return 'customer'
    }
    if (path.includes('/blog')) {
        return 'blog'
    }
    if (path.includes('/integration') || path.includes('/integraties')) {
        return 'integration'
    }
    return null
}
