'use client'

import React, {useCallback} from 'react'

import {buttonClasses} from '@/app/[locale]/(website)/components/ui/ButtonClasses'
import useGlobalKey from '@/app/[locale]/(website)/hooks/useGlobalKey'

interface ToFluxButtonProps {
    label: string
    url: string
    ariaLabel: string
    title: string
    hotkey?: string
}

export default function ToFluxButton({
    label,
    url,
    ariaLabel,
    title,
    hotkey = 'f',
}: ToFluxButtonProps) {
    const navigateToFlux = useCallback(() => {
        window.open(url, '_blank')
    }, [url])

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault()
            navigateToFlux()
        },
        [navigateToFlux],
    )

    useGlobalKey(hotkey, navigateToFlux)

    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className={buttonClasses({
                variant: 'high',
                size: 'medium',
                shape: 'roundXL',
            })}
            onClick={handleClick}
            role="button"
            aria-label={ariaLabel}
            title={title}
            data-purpose="login-link"
        >
            {label}
            <span
                className="hidden lg:flex ml-3 font-semibold items-center justify-center bg-white text-gray-700 font-mono text-xs px-1.5 py-0.5 rounded-md"
                aria-hidden="true"
            >
                {hotkey.toUpperCase()}
            </span>
        </a>
    )
}
